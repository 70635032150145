import Container from 'react-bootstrap/Container';
import HomePage from './pages/HomePage';
import DriverPage from './pages/DriverPage'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Header from "./components/Header"
// import ApiProvider from './contexts/ApiProvider';
// import logo from './logo.svg';
// import './App.css';


export default function App() {
  return (
    <Container fluid className="App">
      {/* <ApiProvider> */}
        <BrowserRouter>
          <Header />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/driver/:drivername" element={<DriverPage />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </BrowserRouter>
      {/* </ApiProvider> */}
    </Container>
  );
}

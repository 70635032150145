import { Card, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';


export default function Driver({ driver }) {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/driver/' + driver.name);
    };

    return (
        <Card>
            <Card.Img variant="top" src={driver.profile_picture}/>
            <Card.Body>
                <Card.Title>{driver.name}</Card.Title>
                <Card.Text>Crazy Turtle Taxi of {driver.region} County</Card.Text>
                <Button variant="primary" onClick={handleClick}>View Profile</Button>
            </Card.Body>
        </Card>
    );
}
import { Row, Col } from 'react-bootstrap';
import { useEffect, useState } from "react";
import Driver from './Driver'
import Spinner from 'react-bootstrap/Spinner';
import { supabase } from './SupabaseClient';
// import { useApi } from '../contexts/ApiProvider';
// import { createClient } from "@supabase/supabase-js";
// const SUPABASE_URL = process.env.SUPABASE_URL;
// const SUPABASE_ANON_KEY = process.env.SUPABASE_ANON_KEY;


export default function Drivers() {


  const [drivers, setDrivers] = useState(null);

  useEffect(() => {
    (async () => {
      const { data, error } = await supabase.from("drivers").select();
      if (error) {
        console.log(error)
      }
      else if (data) {
        setDrivers(data);
      }
    })();
  }, []);

  return (
    <>
      {drivers === undefined ?
        <p>Could not retrieve drivers.</p>
        :
        <>
          {drivers === null ?
            <Spinner animation="border" />
            :
            <Row>
              {drivers.map((driver, index) => (
                <Col xs={6} md={4} lg={2} key={index}>
                  <Driver driver={driver} />
                </Col>
              ))}
            </Row>
          }
        </>
      }
    </>
  );
}